import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getArticleOptionNames } from '../store/actions';
import { useTranslation } from 'react-i18next';

const ArticleOptionsModal: FC<any> = (props) => {
  const { t } = useTranslation();
  const [articleOption, setArticleOption] = useState<any>('');
  const [articleOptionid, setArticleOptionid] = useState<any>('');
  const [selectionofalias, setselectionofalias] = useState<any>([]);
  const [selectionofaliaslist, setselectionofaliaslist] = useState([]);
  const [minstate, setminstate] = useState('');
  const [maxstate, setmaxstate] = useState('');
  const [maxlimit, setmaxlimit] = useState('');


  useEffect(() => {
    if (props.modal) {
      setTimeout(() => {

        // console.log("articleOptionid", articleOptionid)

        if (articleOptionid === "new") {

          let nameArticle = document.getElementById("articleValidation") as HTMLInputElement

          nameArticle.addEventListener("input", function (e) {
            nameArticle.setCustomValidity(""); //remove message when new text is input
          });
          nameArticle.addEventListener("invalid", function (e) {
            nameArticle.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
          });


        } else if (articleOptionid > 0) {

          let nameArticle = document.getElementById("articleValidation") as HTMLInputElement

          nameArticle.addEventListener("input", function (e) {
            nameArticle.setCustomValidity(""); //remove message when new text is input
          });
          nameArticle.addEventListener("invalid", function (e) {
            nameArticle.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
          });

          let nameMin = document.querySelector(".testMin") as HTMLInputElement
          nameMin.addEventListener("input", function (e) {
            nameMin.setCustomValidity(""); //remove message when new text is input
          });

          nameMin.addEventListener("invalid", function (e) {
            if (nameMin.validity.rangeUnderflow) {
              nameMin.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_minvalidation_greater_equal'));
            } else if (nameMin.validity.rangeOverflow) {
              nameMin.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_minvalidation_lessthan_equal'));
            } else if (!nameMin.value) {
              nameMin.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); // Custom validation message for other invalid cases
            }
          });

          let nameMax = document.querySelector(".maxvalidaton") as HTMLInputElement
          console.log(nameMax, "sgajhdgjh")
          nameMax.addEventListener("input", function (e) {
            nameMax.setCustomValidity(""); //remove message when new text is input
          });
          nameMax.addEventListener("invalid", function (e) {
            if (nameMax.validity.rangeUnderflow) {
              nameMax.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_minvalidation_greater_equal'));
            } else if (nameMax.validity.rangeOverflow) {
              nameMax.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_minvalidation_lessthan_equal'));
            } else if (!nameMin.value) {
              nameMax.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); // Custom validation message for other invalid cases
            }
          });
        }
      }, 200)
    }
  }, [props.modal, articleOption, articleOptionid, selectionofalias, selectionofaliaslist, minstate, maxstate, maxlimit])


  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  const getArticleOptionNameSelection = async () => {
    await API.restaurantApi
      .getArticleOptionNameSelection(props.articleoptId)
      .then((res) => {
        setselectionofaliaslist(res.data);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const getArticleOptionForModal = async (restId: string, id: string) => {
    await API.restaurantApi
      .getArticleOptionForModal(restId, id)
      .then((res) => {
        setmaxlimit(res.data.length);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  const getArticleOptionNameMinMax = async () => {
    await API.restaurantApi
      .getArticleOptionNameMinMax(props.articleopt.activetab.id)
      .then((res) => {
        setminstate(res.data[0].min);
        setmaxstate(res.data[0].max);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    typeof props.articleoptId === 'number' && getArticleOptionNameSelection();
    setselectionofalias([]);
    if (props.articleopt) {
      if (props.articleopt.activetab === null) {
        setselectionofalias([]);
        console.log('into props if', props.articleopt.activetab);
      } else if (props.articleopt !== 'undefined' && props.articleopt !== 'new') {
        const stateselection = [...selectionofalias] as any;
        console.log('selected prop', props.articleopt);
        const category = {
          value: props.articleopt.activetab.alias,
          label: props.articleopt.activetab.alias,
          id: props.articleopt.activetab.id,
        };
        stateselection.push(category);
        setselectionofalias(stateselection);

        const rest_id = localStorage.getItem('rest_id');
        getArticleOptionForModal(rest_id as string, props.articleopt.activetab.id);
        getArticleOptionNameMinMax();
        console.log(stateselection);
      }
    }
    console.log("props.articleoptId", props.articleoptId)
    setArticleOptionid(props.articleoptId);
    if (typeof props.articleopt === 'undefined') {
      setArticleOption('');
    } else {
      setArticleOption(props.articleopt);
      console.log(props.articleopt);
    }
  }, [props]);

  const handleChangeSelection = (e: any) => {
    const selection = [{ value: e.value, label: e.value, id: e.id }];
    setselectionofalias(selection);

    const rest_id = localStorage.getItem('rest_id');
    getArticleOptionForModal(rest_id as string, e.id);
    getArticleOptionNameMinMax();
  };

  const changeMinData = (e: any) => {
    setminstate(e.target.value);
  };

  const changeMaxData = (e: any) => {
    setmaxstate(e.target.value);
  };

  const changeOptionNamedata = (e: any) => {
    setArticleOption({
      ...articleOption,
      [e.target.name]: [e.target.value],
    });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (selectionofalias && selectionofalias.length === 0 && articleOptionid !== 'new') {
      toast.error(t('Edit_Product_option.editProductGroup_label_selectArtical'), { containerId: 'B' });
      return;
    }

    const optionname = e.target.elements.optionname.value;

    const restid = localStorage.getItem('rest_id') as string;
    if (articleOptionid === 'new') {
      await API.restaurantApi
        .createArticleOptionName(
          {
            optionname,
            articleoptionnamerestaurant: restid,
          },
          restid
        )
        .then((response) => {
          toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_add_msg'), { containerId: 'B' });
          asyncDispatch(getArticleOptionNames(restid)).then(() => props.toggle());
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      const min = e.target.elements.min.value;
      const max = e.target.elements.max.value;
      const activetab = selectionofalias[0]?.id;
      await API.restaurantApi
        .updateArticleOptionName(
          {
            articleoptionnamerestaurant: restid,
            activetab,
            optionname,
            restaurant: restid,
            optionalais: activetab,
            optionName: optionname,
            min,
            max,
            panel: 'true',
          },
          restid,
          articleOptionid
        )
        .then((response) => {
          toast.success(t('Add_Edit_hours.editOpeningHrs_successfully_updated_msg'), { containerId: 'B' });
          asyncDispatch(getArticleOptionNames(restid)).then(() => props.toggle());
        });
    }
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {articleOptionid === 'new' ? t('Add_Product_option.addProductGroup_title') : t('Edit_Product_option.editProductGroup_title')}
      </ModalHeader>
      {articleOption && (
        <form onSubmit={(e) => handleSubmit(e)}>
          <ModalBody>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group text-left">
                  {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                  <label htmlFor="" className="small">
                    <b>
                      {/* Article Option Name: */}
                      {t('Add_Product_option.addProductGroup_label_name')}
                    </b>
                  </label>
                  <InputField
                    className="form-control"
                    id="articleValidation"
                    name="optionname"
                    placeholder={t('Add_Product_option.addProductGroup_placeholder_name')}
                    value={articleOption.optionname || ''}
                    onChange={(e: any) => changeOptionNamedata(e)}
                    required
                  />
                </div>
              </div>
              {props.articleoptId !== 'new' && (
                <>
                  <div className="col-sm-12">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>
                          {/* Select Article Options: */}
                          {t('Edit_Product_option.editProductGroup_label_selectArtical')}
                        </b>
                      </label>
                      <Select
                        name="selectedoption"
                        defaultValue={selectionofalias}
                        options={selectionofaliaslist}
                        value={selectionofalias}
                        onChange={(e) => handleChangeSelection(e)}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>
                          {/* Enter Min value: */}
                          {t('Edit_Product_option.editProductGroup_label_minvalue')}
                        </b>
                      </label>
                      <InputField
                        id="min"
                        className="form-control testMin"
                        type="number"
                        name="min"
                        // placeholder="Enter Min value :"
                        // max={maxlimit || ''}
                        max={maxstate || ''}
                        min={0}
                        value={minstate}
                        onChange={(e: any) => changeMinData(e)}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="form-group text-left">
                      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                      <label htmlFor="" className="small">
                        <b>
                          {/* Enter Max value: */}
                          {t('Edit_Product_option.editProductGroup_label_maxValue')}
                        </b>
                      </label>
                      <InputField
                        id="maxvalidaton"
                        className="form-control maxvalidaton"
                        type="number"
                        name="max"
                        // placeholder="Enter Max value"
                        min={0}
                        value={maxstate}
                        // max={maxlimit || ''}
                        onChange={(e: any) => {
                          changeMaxData(e);
                          setmaxlimit(e.target.value);
                        }}
                        required
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              name={t('Add_Product_option.addProductGroup_button_cancel')}
              btnLongWidth={false}
              type="button"
              onClick={(e: any) => {
                e.preventDefault();
                props.toggle();
              }}
            />

            <Button name={t('Add_Product_option.addProductGroup_button_save')}
              type="submit" dangerButton={true} btnLongWidth={false} />
          </ModalFooter>
        </form>
      )}
    </Modal>
  );
};
export default ArticleOptionsModal;
