import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../services/urls';
import { Header, Sidebar, InputField } from '../../Components';
import { API } from '../../api';
import { showError } from '../../api/errorHundler';
import { useTranslation } from 'react-i18next';

const CreateArticleOptions: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [cats, setCats] = useState<any>([
    {
      choice: '',
      choice_price: '',
      id: '',
    },
  ]);
  const [alias, setAlias] = useState('');
  const [description, setDescription] = useState('');
  const [min, setMin] = useState('');
  const [max, setMax] = useState('');

  const params = useParams<any>();

  useEffect(() => {
    document.title = 'Product Option';
    // @ts-ignore
    document.getElementById('articleoptiongroup_colorchange').style.color = 'darkred';
  }, []);

  useEffect(() => {
    let name = document.getElementById('alias') as HTMLInputElement;
    // console.log(name,"test")

    name.addEventListener('input', function (e) {
      name.setCustomValidity(''); //remove message when new text is input
    });
    name.addEventListener('invalid', function (e) {
      name.setCustomValidity(
        t('Create_Article_Options.createArticalOption_validation_alias_empty')
      ); //custom validation message for invalid text
    });

    let nameChoice = document.querySelector('.choicevalidation') as HTMLInputElement;

    nameChoice.addEventListener('input', function (e) {
      nameChoice.setCustomValidity(''); //remove message when new text is input
    });
    nameChoice.addEventListener('invalid', function (e) {
      nameChoice.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
    });

    let namePrice = document.querySelector('.pricevalidation') as HTMLInputElement;

    namePrice.addEventListener('input', function (e) {
      namePrice.setCustomValidity(''); //remove message when new text is input
    });
    namePrice.addEventListener('invalid', function (e) {
      namePrice.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
    });

    let nameMin = document.getElementById('min') as HTMLInputElement;

    // console.log(nameMin,"test")
    nameMin.addEventListener('input', function (e) {
      nameMin.setCustomValidity(''); //remove message when new text is input
    });
    nameMin.addEventListener('invalid', function (e) {
      nameMin.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
    });

    let nameMax = document.getElementById('max') as HTMLInputElement;

    // console.log(nameMin,"test")
    nameMax.addEventListener('input', function (e) {
      nameMax.setCustomValidity(''); //remove message when new text is input
    });
    nameMax.addEventListener('invalid', function (e) {
      nameMax.setCustomValidity(t('Add_Edit_hours.addOpeningHrs_placeholder_validation')); //custom validation message for invalid text
    });
  }, []);

  const handleChange = (e: any) => {
    if (['choice', 'choice_price', 'id'].includes(e.target.name)) {
      const _cats = [...cats];
      _cats[e.target.dataset.id][e.target.name] = e.target.value;
      _cats[e.target.dataset.id].id = e.target.id;
      console.log(`my id${_cats[e.target.dataset.id].id}`);
      setCats(_cats);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (e.target.name === 'alias') {
        setAlias(e.target.value);
      } else if (e.target.name === 'description') {
        setDescription(e.target.value);
      } else if (e.target.name === 'min') {
        setMin(e.target.value);
      } else {
        setMax(e.target.value);
      }
    }
  };

  const addCat = (e: any) => {
    setCats([...cats, { choice: '', choice_price: '', id: '' }]);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const url = params.id;
    const rest_id = localStorage.getItem('rest_id') as string;
    const filtered = cats.filter((el: any) => el.choice !== '');

    if (filtered.length > 0) {
      await API.restaurantApi
        .createArticleOption(
          {
            cats,
            alias,
            description,
            min,
            max,
          },
          rest_id,
          url
        )
        .then(async (res) => {
          const aliasId = res.data;
          const optionnameid = params.id;
          await API.restaurantApi
            .updateArticleOptionName(
              {
                articleoptionnamerestaurant: rest_id,
                activetab: aliasId,
                optionname: optionnameid,
                restaurant: rest_id,
                optionalais: aliasId,
                optionName: optionnameid,
                min,
                max,
                panel: 'false',
              },
              rest_id,
              url
            )
            .then((response) => {
              toast.success('Products Option Added  Successfully!', { containerId: 'B' });
              setTimeout(() => {
                // window.location.reload();
                // window.location.href = ''/admin/articleoptionsName'';
                history.push('/admin/articleoptionsName');
              }, 1000);
            })
            .catch((error) => {
              toast.error('Some Error in Request!', { containerId: 'B' });
            });

          // toast.success('Products Option Added  Successfully!', { containerId: 'B' });
          setTimeout(() => {
            // window.location.reload();
            // window.location.href = '/admin/articleoptionsName';
            history.push('/admin/articleoptionsName');
          }, 2400);
        })
        .catch((err) => showError(err));
    } else {
      toast.error('Products Option Empty is not allowed!', { containerId: 'B' });
    }
  };

  const handleClick = (e: any) => {
    setCats(cats.filter((el: any) => el.id !== e.target.id));
    // @ts-ignore
    document.getElementById(e.target.id).remove();
  };

  const changeOption = (e: any) => {
    // this.setState({
    //   [e.target.name]: [e.target.value],
    // });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel">
        <Header />
        <form onSubmit={handleSubmit} onChange={handleChange}>
          <div className="row option_group">
            <div className="col-md-12 mb-5">
              <h3 className="text-center ">
                {/* Create Article Options */}
                {t('Create_Article_Options.createArticalOption_title')}
              </h3>
            </div>
            <div className="col-md-3" />
            <div className="col-md-3">
              <label htmlFor="name">
                <b>
                  {/* Alias*/}
                  {t('Create_Article_Options.createArticalOption_alias')}
                </b>
              </label>
              <input type="text" className="form-control" name="alias" id="alias" required />
              <button
                type="button"
                className="btn btn-success rounded-0 w-25 float-right mt-2 shadow-none"
                onClick={addCat}
              >
                +
              </button>
            </div>
            <div className="col-md-2" />
            <div className="col-md-3" />
          </div>
          {cats.map((val: any, idx: number) => {
            const catId = `cat-${idx}`;
            const ageId = `age-${idx}`;
            return (
              <div id={ageId} key={idx}>
                <div className="row mt-5">
                  <div className="col-md-3" />
                  <div className="col-md-3">
                    <label htmlFor={catId}>
                      {/* <b>{`Cat #${idx + 1}`}</b> */}
                      <b>
                        {/* Choice */}
                        {t('Create_Article_Options.createArticalOption_label_choice')}
                      </b>
                    </label>
                    <input
                      type="text"
                      name="choice"
                      data-id={idx}
                      id={catId}
                      value={cats[idx].name}
                      onChange={(e) => setCats([...cats, { choice: e.target.value }])}
                      className="name form-control choicevalidation"
                      required
                    />
                  </div>
                  <div className="col-md-3">
                    <label htmlFor={ageId}>
                      <b>
                        {/* Price */}
                        {t('Create_Article_Options.createArticalOption_label_price')}
                      </b>
                    </label>
                    <input
                      type="number"
                      name="choice_price"
                      data-id={idx}
                      id={ageId}
                      value={cats[idx].choice_price}
                      onChange={(e) => setCats([...cats, { choice_price: e.target.value }])}
                      className="age form-control pricevalidation"
                      required
                    />

                    <input
                      type="input"
                      name="id"
                      data-id={idx}
                      id={ageId}
                      style={{ display: 'none' }}
                      className="age form-control"
                    />

                    <input
                      type="button"
                      className="btn btn-success rounded-0 w-25 float-right mt-2 shadow-none"
                      onClick={addCat}
                      value="+"
                    />

                    <input
                      type="button"
                      name={ageId}
                      data-id={idx}
                      id={ageId}
                      value="-"
                      className="age float-right mt-2 mr-3 btn btn-danger w-25 rounded-0 w-25 shadow-none"
                      onClick={handleClick}
                    />
                  </div>
                  <div className="col-md-3" />
                </div>
              </div>
            );
          })}
          <div className="row mt-3">
            <div className="col-md-3" />
            <div className="col-md-3">
              <label htmlFor="name">
                <b>
                  {/* Min Selection */}
                  {t('Create_Article_Options.createArticalOption_label_minSelection')}
                </b>
              </label>
              <InputField
                type="number"
                className="form-control"
                name="min"
                id="min"
                max={cats ? cats.length : ''}
                min={0}
                required
              />
              {/* <span style={{color:'red'}}>{t('Create_Article_Options.createArticalOption_label_minSelection')}</span> */}
            </div>
            <div className="col-md-3">
              <label htmlFor="description">
                <b>
                  {/* Max Selection */}
                  {t('Create_Article_Options.createArticalOption_label_maxSelection')}
                </b>
              </label>
              <InputField
                type="number"
                className="form-control maxvalidation"
                name="max"
                id="max"
                min={0}
                max={cats ? cats.length : ''}
                required
              />

              <input
                className="float-right btn btn-success mt-3 mb-5"
                type="submit"
                value={t('Create_Article_Options.createArticalOption_button_submit')}
              />
            </div>
            <div className="col-md-3" />
          </div>
        </form>
      </div>
    </>
  );
};
export default CreateArticleOptions;
