import React, { FC } from 'react';
import { Redirect, Route, Switch, Router } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Login from './views/Login';
import ForgotPassword from './views/ForgotPassword';
import AdminDashboard from './views/AdminDashboard';
import CsvUpload from './views/UploadCSV';
import EditArticle from './views/EditArticle';
import FoodCategory from './views/FoodCategory';
import ForgotPasswordReset from './views/ForgotPasswordReset';
import TablesQRCodes from './views/TablesQRCodes';
import ArticleOptions from './views/ArticleOptions';
import ArticleOptionsFiltered from './views/ArticleOptionsFiltered';
import ArticleOptionsName from './views/ArticleOptionsName';
import CreateArticleOptions from './views/CreateArticleOptions';
import GeneralAgreements from './views/GeneralAgreements';
import Orders from './views/Orders';
import Printers from './views/Printers';
import ChangePassword from './views/ChangePassword';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import PrivateRoute from './Components/PrivateRoute';
import i18n from "i18next";
import { Trans, initReactI18next, useTranslation } from 'react-i18next';
import { localization } from "./i18n/index"
import history from './history';
import Customer from './views/Customer';
import CustomerEditForm from './views/Customer/EditForm';

// i18n.use(initReactI18next).init(localization);
i18n.use(initReactI18next).init({ ...localization, lng: localStorage.getItem('yopla_lang') || "Dutch" });
const App: FC = () => {
  return (
    <>
      <Router history={history}>
        <Switch>
          <Route exact path="/">
            <Redirect to="/auth/login" />
          </Route>
          <Route path="/auth/login" component={Login} />
          <Route path="/auth/forgotPassword" component={ForgotPassword} />
          {/* Hide Printers functionality */}
          {/* <PrivateRoute path="/admin/printers" component={Printers} /> */}
          <PrivateRoute path="/admin/dashboard" component={AdminDashboard} />
          <PrivateRoute path="/admin/csvUpload" component={CsvUpload} />
          <PrivateRoute path="/admin/editArticle" component={EditArticle} />
          <PrivateRoute path="/admin/foodCategory" component={FoodCategory} />
          <PrivateRoute path="/admin/articleoptions" component={ArticleOptions} />
          <PrivateRoute path="/admin/articleoption/:id" component={ArticleOptionsFiltered} />
          <PrivateRoute path="/admin/articleoptionsName" component={ArticleOptionsName} />
          <PrivateRoute path="/admin/qrdonwload/:id" component={TablesQRCodes} />
          <PrivateRoute path="/admin/optiongroups/:id" component={CreateArticleOptions} />
          <PrivateRoute path="/admin/orders" component={Orders} />
          <PrivateRoute exact={true} path="/admin/customer" component={Customer} />
          <PrivateRoute path="/admin/customer/edit/:id" component={CustomerEditForm} />
          <PrivateRoute path="/admin/changePassword" component={ChangePassword} />
          <PrivateRoute path="/admin/GeneralAgreements" component={GeneralAgreements} />

          <Route
            path="/auth/restaurant/:restid/forgotPasswordReset/:code"
            component={ForgotPasswordReset}
          />
        </Switch>
      </Router>
      <ToastContainer enableMultiContainer containerId="B" position={toast.POSITION.TOP_LEFT} />
    </>
  );
};
export default App;
