import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Button, InputField } from './index';
import { API } from '../api';
import { getCategories } from '../store/actions';
import { useTranslation } from 'react-i18next';

const CategoryModal: FC<any> = (props) => {
  const { t } = useTranslation();
  const [category, setcategory] = useState<any>('');
  const [categoryid, setcategoryId] = useState<any>(false);
  const restId = localStorage.getItem('rest_id') as string;

  const asyncDispatch: ThunkDispatch<StoreRoot, any, AnyAction> = useDispatch();

  useEffect(() => {
    setcategoryId(props.catid);
    if (typeof props.category === 'undefined') {
      setcategory('');
    } else {
      setcategory(props.category.value || '');
    }
  }, [props]);
  
  useEffect(() => {
    if(props.modal){
      setTimeout(() => {
        let name = document.getElementById("validation") as HTMLInputElement
        name.addEventListener("input", function (e) {
          name.setCustomValidity(""); //remove message when new text is input
        });
        name.addEventListener("invalid", function (e) {
          name.setCustomValidity(t('Add_Edit_Category.addCategory_validation_name_empty')); //custom validation message for invalid text
        });
      },100)

    }
  }, [props.modal]);


  const changeOptionName = (e: any) => {
    setcategory(e.target.value);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const catname = e.target.elements.catname.value;
    const id = localStorage.getItem('rest_id');

    if (props.category === 'new') {
      await API.restaurantApi
        .createCategory(
          {
            name: catname,
            restaurant: id as string,
          },
          id as string
        )
        .then((response) => {
          toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_add_msg'), { containerId: 'B' });
          asyncDispatch(getCategories(restId)).then(() => props.toggle());
        });
    } else {
      await API.restaurantApi
        .updateCategory(
          {
            name: catname,
            restaurant: id as string,
          },
          id as string,
          categoryid as string
        )
        .then(() => {
          toast.success(t('Add_Edit_hours.editOpeningHrs_successfully_updated_msg'), {
            containerId: 'B',
          });
          asyncDispatch(getCategories(restId)).then(() => props.toggle());
        });
    }
  };

  const handleCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    props.toggle && props.toggle();
  };

  return (
    <Modal isOpen={props.modal} toggle={props.toggle}>
      <ModalHeader toggle={props.toggle}>
        {props.category === 'new'
          ? t('Add_Edit_Category.addCategory_title')
          : t('Add_Edit_Category.editCategory_title')}
      </ModalHeader>
      <form onSubmit={(e) => handleSubmit(e)}>
        <ModalBody>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group text-left">
                {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
                <label htmlFor="" className="small">
                  {/* Naam */}
                  {t('Add_Edit_Category.addCategory_name')}
                </label>
                <InputField
                className="form-control"
                  required
                  name="catname" 
                  id="validation"
                  value={category}
                  onChange={(e: any) => changeOptionName(e)}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            name={t('Add_Edit_Category.addCategory_button_cancel')}
            btnLongWidth={false}
            onClick={handleCancel}
            type="button"
          />
          <Button
            name={t('Add_Edit_Category.addCategory_button_save')}
            type="submit"
            dangerButton={true}
            btnLongWidth={false}
            // onClick={props.toggle}
          />
        </ModalFooter>
      </form>
    </Modal>
  );
};
export default CategoryModal;
