import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Header, Sidebar, ArticleOptiongroupModel } from '../../Components';
import { API } from '../../api';
import { getArticleOptions } from '../../store/actions';
import { useTranslation } from 'react-i18next';

let ps: any;
const ArticleOptions: FC = (props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [articleoptId, setArticleoptId] = useState(0);
  const [Articleoptobj, setArticleoptobj] = useState(0);

  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const articleOptions = useSelector(({ articleOptions }: StoreRoot) => articleOptions);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Article Options';
    // @ts-ignore
    document.getElementById('articleoption_colorchange').style.color = 'darkred';
    dispatch(getArticleOptions(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, articleopt: any) => {
    setModal(!modal);
    setArticleoptId(id);
    setArticleoptobj(articleopt);
  };

  const deleteoptionnama = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteArticleOption(restid as string, id)
      .then((response) => {
        toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_delete_msg'), { containerId: 'B' });
        dispatch(getArticleOptions(id));
        window.location.reload();
      })
      .catch((error) => {
        toast.error(t('Add_Edit_hours.addOpeningHrs_successfully_delete_msg'), { containerId: 'B' });
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {articleOptions && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">
                {/* Beheer productopties */}
                {t('Article_Options_table.articalOption_title')}
                </span>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {/* <th className='small'>Article ID</th> */}
                      <th className="small">
                        {/* Optie */}
                        {t('Article_Options_table.articalOption_table_header_options')}
                        </th>
                      <th className="small">
                        {/* Keuze optie */}
                        {t('Article_Options_table.articalOption_table_header_choiceName')}
                        </th>
                      <th className="small">
                        {/* Prijs optie */}
                        {t('Article_Options_table.articalOption_table_header_choicePrice')}
                        </th>
                      <th className="small">
                        {/* Naam optie */}
                        {t('Article_Options_table.articalOption_table_header_optionName')}
                        </th>
                      <th className="small">
                        {/* Actie */}
                        {t('Article_Options_table.articalOption_table_header_action')}
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {articleOptions.map((articleopt: any, index: number) => {
                      return (
                        <React.Fragment key={index}>
                          <tr>
                            {/* <td>{articleopt.id}</td> */}
                            <td>{articleopt.optionname.optionname}</td>
                            <td>{articleopt.choice}</td>
                            <td>{articleopt.choice_price}</td>
                            <td>{articleopt.seletedalias ? articleopt.seletedalias.alias : ''}</td>
                            <td>
                              <i
                                className="fa fa-pencil cursr"
                                aria-hidden="true"
                                id={articleopt.id}
                                onClick={() => toggle(articleopt.id, articleopt)}
                              />
                              <i
                                className="fa fa-trash pl-2 cursr"
                                aria-hidden="true"
                                onClick={() => deleteoptionnama(articleopt.id)}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <ArticleOptiongroupModel
        articleopt={Articleoptobj}
        modal={modal}
        toggle={toggle}
        articleoptId={articleoptId}
      />
    </>
  );
};
export default ArticleOptions;
