import PerfectScrollbar from 'perfect-scrollbar';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { CategoryModal, Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { getCategories } from '../../store/actions';
import { useTranslation } from 'react-i18next';

let ps: any;
const FoodCategory: FC = (props) => {
  const { t } = useTranslation();
  const [modal, setModal] = useState(false);
  const [categoryId, setCategoryId] = useState(0);
  const [Categoryobj, setCategoryobj] = useState(0);
  const mainPanel = useRef<any>();
  const id = localStorage.getItem('rest_id') as string;

  const category = useSelector(({ categories }: StoreRoot) => categories);

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = 'Food Categories';
    // @ts-ignore
    document.getElementById('foodcategories_colorchange').style.color = 'darkred';
    dispatch(getCategories(id));
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (id: any, cat: any) => {
    if (id?.preventDefault) {
      id.preventDefault();
    }

    setModal(!modal);
    setCategoryId(id);
    setCategoryobj(cat);
  };

  const deletecat = async (id: any) => {
    const restid = localStorage.getItem('rest_id') as string;
    await API.restaurantApi
      .deleteCategory(id, restid as string)
      .then((response) => {
        toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_delete_msg'), { containerId: 'B' });
        dispatch(getCategories(restid));
      })
      .catch((error) => {
        toast.success(t('Add_Edit_hours.addOpeningHrs_successfully_delete_msg'), { containerId: 'B' });
        dispatch(getCategories(restid));
      });
  };

  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        {category && (
          <div className="row ml-lg-5 mt-5 pt-5">
            <div className="col-xl-10 offset-xl-1 col-lg-8 offset-lg-3 col-md-8 offset-md-4 col-sm-12 col-12 text-center">
              <span className="h4">
                {/* Categories */}
                {t('Category.category_title')}
                </span>
              &nbsp;&nbsp;&nbsp;
              <button
                type="button"
                onClick={() => toggle('new', 'new')}
                className="btn btn-danger rounded-0 shadow-none"
              >
                {/* + Voeg category toe */}
                {t('Category.category_button_add')}
              </button>
              <div className="table-responsive mt-4">
                <table className="table table-striped table-bordered">
                  <thead>
                    <tr>
                      {/* <th className='small'>Category ID</th> */}
                      <th className="small">
                        {/* Naam */}
                        {t('Category.category_table_header_name')}
                        </th>
                      <th className="small">
                        {/* Actie */}
                        {t('Category.category_table_header_Actie')}
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                    {category.map((cat: any) => {
                      return (
                        <tr key={cat.id}>
                          {/* <td>{cat.id}</td> */}
                          <td>{cat.value}</td>
                          <td>
                            <i
                              className="fa fa-pencil cursr"
                              aria-hidden="true"
                              id={cat.id}
                              onClick={() => toggle(cat.id, cat)}
                            />
                            <i
                              className="fa fa-trash pl-2 cursr"
                              aria-hidden="true"
                              onClick={() => deletecat(cat.id)}
                              id={cat.id}
                            />
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>
      <CategoryModal category={Categoryobj} modal={modal} toggle={toggle} catid={categoryId} />
    </>
  );
};
export default FoodCategory;
