import { toast } from 'react-toastify';
import React, { FC, useEffect } from 'react';
import { Logo, InputField, Button } from '../../Components';
import { API_BASE_URL } from '../../services/urls';
import { API } from '../../api';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const ForgotPassword: FC = () => {
  const { t } = useTranslation();
  useEffect(() => {
    document.title = 'Forgot Password';
  }, []);
  const history = useHistory();

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const email = e.target.elements.email.value;
    const formDataNew = new FormData();
    formDataNew.append('email', email);
    await API.restaurantApi
      .forgetPassword(formDataNew)
      .then((response) => {
        //@ts-ignore
        if (response?.success && response?.message) {
          //@ts-ignore
          toast.success(response?.message, { containerId: 'B', });
          history.push('/auth/login');
        } else {
          toast.error(t("Forget_Password.forgetPassword_email_not_exists"), { containerId: 'B' });
        }
        // toast.success(t("Forget_Password.forgetPassword_success_mailSend_msg"), {
        //   containerId: 'B',
        // });
        // window.location.href = '/auth/login';
      })
      .catch((error) => {

        console.log(error, 'pkk');

        if (error?.response?.data?.message) {
          toast.error(error?.response?.data?.message, { containerId: 'B' });
        } else {
          toast.error("Something went wrong", { containerId: 'B' });
        }
      });
  };

  return (
    <div className="container mt-5">
      <form onSubmit={(e) => handleSubmit(e)}>
        <div className="row align-items-center">
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12">
            <Logo infoText={t("Forget_Password.forgetPassword_label_email")}
              mutedText={true} />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 mt-2 mb-2">
            <InputField name="email" placeholder="restaurant@web.com" className="form-control" />
          </div>
          <div className="col-lg-4 offset-lg-4 col-md-6 offset-md-3 col-sm-6 offset-sm-3 col-12 text-center mt-2">
            <Button name={t("Forget_Password.forgetPassword_submit")}
              dangerButton={true} btnLongWidth={false} />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
