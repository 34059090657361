import PerfectScrollbar from 'perfect-scrollbar';
import QRCode from 'qrcode.react';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { Header, Sidebar } from '../../Components';
import { API } from '../../api';
import { MAIN_APP_URL } from '../../services/urls';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

let ps: any;
const TablesQRCodes: FC = () => {
  const { t } = useTranslation();
  const mainPanel = useRef<any>();
  const [modal, setModal] = useState(false);
  const [tableid, settableId] = useState(false);
  const [restaurantData, setRestaurantData] = useState<any>([]);

  const { id } = useParams<any>();

  const getRestaurant = async () => {
    await API.restaurantApi
      .getRestaurant(id)
      .then((res) => {
        setRestaurantData(res.data[0]);
        console.log(res.data[0]);
      })
      .catch((err) => console.log('Error is', err.message));
  };

  useEffect(() => {
    document.title = 'QR Download';
    // @ts-ignore
    document.getElementById('restaurant_qrdownload').style.color = 'darkred';
    getRestaurant();

    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(mainPanel.current);
      document.body.classList.toggle('perfect-scrollbar-on');
    }
    return () => {
      if (navigator.platform.indexOf('Win') > -1) {
        ps.destroy();
        document.body.classList.toggle('perfect-scrollbar-on');
      }
    };
  }, [modal, id]);

  const toggle = (e: any) => {
    setModal(!modal);
    settableId(e?.target?.id || '');
  };

  //   const toggle = () => setModal(!modal);
  const downloadQR = () => {
    const canvas = document.getElementById('123456') as any;
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
    const downloadLink = document.createElement('a');
    downloadLink.href = pngUrl;
    downloadLink.download = '12345.png';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const renderQr = (movies: any) => {
    const items = [];
    for (let i = 1; i <= restaurantData.NumberOfTables; i++) {
      items.push(
        <tr key={i}>
          <td>
            <p className="mt-1">Tafelnummer:{i}</p>{' '}
          </td>
          <td />
          <td />
          <td className="text-center">
            <div className="text-center">
              <Button id={`${i}`} color="danger" onClick={(e: any) => toggle(e)}>
                {/* Maak qr code */}
                {t('Tables_QR_Codes.tableQRcode_action_button_title')}
              </Button>
            </div>
          </td>
        </tr>
      );
    }
    return items;
  };
  return (
    <>
      <Sidebar />
      <div id="border-top-radius" className="container-fluid main-panel" ref={mainPanel}>
        <Header />
        <div className="row ml-lg-5 mt-5 pt-5" />
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className='h4'>
                {t('Tables_QR_Codes.title')}
              </div>
              <div className='row'>
                <div className="col-md-2" />
                <div className="col-md-8">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className='border-0 text-left' style={{ fontSize: '1.5rem', fontWeight: 'lighter' }}>
                          {restaurantData?.RestaurantName}
                        </th>
                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <th className='border-0' />
                        <th className='border-0' />
                        <th className="text-center border-0">
                          <Button color="danger" onClick={(e: any) => toggle('')}>
                            {t('Tables_QR_Codes.tableQRcode_action_button_title')}
                          </Button>
                        </th>
                      </tr>
                    </thead>
                  </table>


                </div>
                <div className="col-md-2" />
              </div>
              <b>
                {/* Tables QR Codes */}
                {t('Tables_QR_Codes.tableQRcode_title')}
              </b>
            </div>

            <div className="col-md-2" />
            <div className="col-md-8 mt-5">
              <table className="table border ">
                <thead>
                  <tr>
                    <th>
                      {/* Tafelnummer */}
                      {t('Tables_QR_Codes.tableQRcode_panel_number')}
                    </th>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <th />
                    <th />
                    <th className="text-center">
                      {/* Actie */}
                      {t('Tables_QR_Codes.tableQRcode_action')}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <>{renderQr(20)}</>
                </tbody>
              </table>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} />
        <ModalBody>
          <div className="text-center">
            <QRCode
              id="123456"
              value={tableid ? `${MAIN_APP_URL}/${restaurantData.slug}/${tableid}` : `${MAIN_APP_URL}/restaurant/${restaurantData.slug}`}
              size={290}
              level="H"
              includeMargin={true}
            />
            <button
              type="button"
              className="cursor btn btn-success ml-1 border-0"
              onClick={downloadQR}
            >
              {' '}
              {/* Download QR */}
              {t('Tables_QR_Codes.tableQRcode_download_QR')}
              {' '}
            </button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default TablesQRCodes;
