import { toast } from 'react-toastify';
import React, { FC, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { API } from '../../api';
import { Logo, InputField, Button } from '../../Components';
import { useTranslation } from 'react-i18next';

const Login: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    document.title = 'Login';
  }, []);

  useEffect(() => {
    let name = document.getElementById("validation") as HTMLInputElement;
    console.log(name, "test")

    name.addEventListener("input", function (e) {
      name.setCustomValidity(""); //remove message when new text is input
    });
    name.addEventListener("invalid", function (e) {
      name.setCustomValidity(t('Login.login_validation_email_not_valid')); //custom validation message for invalid text
    });
  }, []);

  const handleFormSubmission = async (event: any) => {
    event.preventDefault();
    const username = event.target.elements.username.value;
    const password = event.target.elements.password.value;
    await API.restaurantApi
      .login({ username, password })
      .then((response) => {
        console.log(response.data.data.token);
        window.localStorage.setItem('jwt', response.data.data.token);
        window.localStorage.setItem('rest_id', response.data.data.restaurant.id);
        localStorage.setItem('user', JSON.stringify(response?.data?.data?.restaurant?.user));
        if (response.data.data) {
          // @ts-ignore
          // window.location = `/admin/dashboard/${response.data.data.restaurant.id}`;
          history.push(`/admin/dashboard/${response.data.data.restaurant.id}`);
        }
      })
      .catch((error) => {
        toast.error(t('Login.login_validation_email_password_wrong'), { containerId: 'B' });
      });
  };

  return (
    <form onSubmit={(event) => handleFormSubmission(event)}>
      <div className="container mt-5">
        <div className="row align-items-center">
          <div className="col-lg-12 d-flex justify-content-center">
            <Logo infoText={t('Login.login_subtitle')} mutedText={false} />
          </div>
          <div className="col-lg-12 d-flex justify-content-center pb-3">
            <InputField
              className="form-control"
              type="email"
              name="username"
              id="validation"
              placeholder={t('Login.login_placeholder_email')}
              style={{ width: '20em' }}
            />
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <InputField
              className="form-control"
              type="password"
              name="password"
              placeholder={t('Login.login_placeholder_password')}
              style={{ width: '20em' }}
            />
          </div>
          <div className="col-lg-12 d-flex justify-content-end pb-2" style={{ maxWidth: '53em' }}>
            <Link to="/auth/forgotPassword">
              <span className="text-muted small">
                {/* Forgot Password? */}
                {t('Login.login_navigate_forget_password')}
              </span>
            </Link>
          </div>
          <div className="col-lg-12 d-flex justify-content-center">
            <Button
              htmlType="submit"
              name={t('Login.login_button')}
              dangerButton={true}
              Link={true}
              style={{ width: '10em' }}
            />
          </div>
        </div>
      </div>
    </form>
  );
};
export default Login;
